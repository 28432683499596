import {get,post,put,put2,$delete} from '@/plugins/axios.js'

export default{
	
	async post(params) {
		return await post('/api/project-merchants', params);
	},
	async del(params) {
		return await $delete('/api/project-merchants', params);
	},
	async put(params) {
		return await put('/api/project-merchants', params);
	},
	async approval(params) {
		return await put('/api/project-merchants/approval', params);
	},
	async get(params) {
		return await get('/api/project-merchants', params);
	},
	async list(params) {
		return await get('/api/project-merchants/list', params);
	},
	async years(params) {
		return await get('/api/project-merchants/years', params);
	},
	
	
}